<template>
  <div class="app-admin-wrap layout-sidebar-vertical clearfix sidebar-full">
    <verticalSidebar />
    <main>
      <div
        class="main-content-wrap mt-0 bg-off-white"
        :class="{
          'vertical-sidebar': getVerticalSidebar.isVerticalSidebar,
          compact: getVerticalSidebar.isVerticalCompact
        }"
      >
        <verticalTopbar />
        <transition name="page" mode="out-in">
          <router-view />
        </transition>
        <appFooter />
      </div>
    </main>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import verticalSidebar from './verticalSidebar.vue';
import verticalTopbar from './verticalTopbar.vue';
import appFooter from '../common/footer.vue';

export default {
  components: {
    verticalSidebar,
    verticalTopbar,
    appFooter,
  },
  computed: {
    ...mapGetters(['getVerticalSidebar']),
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
